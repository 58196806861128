import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isEmpty, isNil } from "lodash-es";
import Loader from "react-loader-spinner";
import * as Dompurify from "dompurify";
import { getConfig } from "store/configSlice";
import { useQuery, languageQuerytoLocale } from "./RegistrationScreen";
import { LanguageContext } from "../lang/LanguageContextProvider";

const NonDisclosureAgreementScreen = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { changeLanguageFromLocale } = useContext(LanguageContext);
  const qr = query.get("qr");
  const language = query.get("language");

  const locale = languageQuerytoLocale(language);

  changeLanguageFromLocale(locale);

  const { config } = useSelector((state) => state.config);

  const [isLoading, setIsLoading] = useState(true);

  const render = useRef();
  render.current = Dompurify.sanitize(config.agreementText, {
    USE_PROFILES: { html: true },
  });

  useEffect(() => {
    const ndaErrorMessage = (
      <div className="nda.error.message">
        <FormattedMessage
          id="nda.not.found"
          defaultMessage="...Something went wrong"
          description="displayed messsage when link broken and/or api call failed"
        />
      </div>
    );
    if (isEmpty(qr)) {
      console.error("no qr param found");
      render.current = ndaErrorMessage;
    }
    dispatch(getConfig(qr)).then((result) => {
      if (!isNil(result.error)) {
        console.error("api call error", result.error);
        render.current = ndaErrorMessage;
        return;
      }
      setIsLoading(false);
    });

    return () => setIsLoading(false);
  }, [dispatch, qr]);

  return (
    <div className="nda-screen">
      {isLoading ? (
        <div className="nda-screen__company-logo"></div>
      ) : (
        <img
          className="nda-screen__company-logo"
          src={config.site.organization.logoUrl}
          alt="company logo"
        />
      )}
      <div className="nda-screen__box">
        {isLoading ? (
          <Loader type="Puff" color="#FFC110" />
        ) : (
          <div
            className="nda-screen__content"
            dangerouslySetInnerHTML={{ __html: render.current }}
          />
        )}
      </div>
      <div className="nda-screen__filler" />
      <div className="nda-screen__footer">
        <p className="nda-screen__signature">
          <FormattedMessage
            id="nda.signature"
            defaultMessage="Powered by <strong>ProSpace</strong>"
            description="ProSpace signature"
            values={{
              strong: (text) => <strong>{text}</strong>,
            }}
          />
        </p>
        <div className="nda-screen__links">
          <a
            href="https://scheduling-dev.prospace.io/term-and-condition"
            target="__blank"
          >
            <FormattedMessage
              id="nda.terms.and.condition"
              defaultMessage="Terms & Condition"
              description="Terms & Condition"
            />
          </a>
          <a
            href="https://scheduling.prospace.io/privacy-policy"
            target="__blank"
          >
            <FormattedMessage
              id="nda.privacy.policy"
              defaultMessage="Privacy Policy"
              description="Privacy Policy"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NonDisclosureAgreementScreen;
