import React, { useCallback } from "react";
import Loader from "react-loader-spinner";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";

import LanguageCode from "./languageCode";

import English from "../lang/compiledLang/en.json";
import Japanese from "../lang/compiledLang/ja.json";
import Chinese from "../lang/compiledLang/zh.json";
import Korean from "../lang/compiledLang/ko.json";

// This is optional but highly recommended from the documentation
// since it prevents memory leak
const intlCache = createIntlCache();
// use this object if your translation is outside the react
// eslint-disable-next-line import/no-mutable-exports
let intl = createIntl(
  {
    locale: LanguageCode.ENGLISH,
    messages: English,
  },
  intlCache
);

const LanguageContext = React.createContext();

const LanguageContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [locale, setLocale] = React.useState(LanguageCode.ENGLISH);

  const changeLanguage = useCallback((newLocale, newMessages) => {
    intl = createIntl(
      {
        locale: newLocale,
        messages: newMessages,
      },
      intlCache
    );

    setLocale(newLocale);
    document.documentElement.setAttribute("lang", newLocale);
  }, []);

  const changeLanguageFromLocale = useCallback(
    (newLocale) => {
      switch (newLocale) {
        case LanguageCode.JAPAN: {
          changeLanguage(LanguageCode.JAPAN, Japanese);
          break;
        }
        case LanguageCode.CHINESE: {
          changeLanguage(LanguageCode.CHINESE, Chinese);
          break;
        }
        case LanguageCode.KOREAN: {
          changeLanguage(LanguageCode.KOREAN, Korean);
          break;
        }
        default: {
          changeLanguage(LanguageCode.ENGLISH, English);
        }
      }
    },
    [changeLanguage]
  );

  React.useEffect(() => {
    setIsLoading(true);

    const userDefaultLocale = LanguageCode.ENGLISH;

    changeLanguageFromLocale(userDefaultLocale);
    setIsLoading(false);
  }, [changeLanguageFromLocale]);

  if (isLoading) {
    return <Loader type="Puff" color="#FFC110" />;
  }

  return (
    <LanguageContext.Provider
      value={{
        locale,
        changeLanguageFromLocale,
      }}
    >
      <RawIntlProvider value={intl}>{children}</RawIntlProvider>
    </LanguageContext.Provider>
  );
};

export { intl, LanguageContextProvider, LanguageContext };
