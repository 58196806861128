import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchConfig, saveRegister, fetchInvitation } from "services/register";

// ASYNC THUNKS
export const getConfig = createAsyncThunk("getConfig", async (query) => {
  try {
    localStorage.setItem("queryString", query);
    const res = await fetchConfig(query);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "Get config failed");
  }
});

export const register = createAsyncThunk("register", async (params) => {
  try {
    const res = await saveRegister(params);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? "register failed");
  }
});

export const getInvitation = createAsyncThunk(
  "getInvitation",
  async (registerId) => {
    try {
      const res = await fetchInvitation(registerId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "register failed");
    }
  }
);

const initialState = {
  config: {},
  registrationId: null,
  invitation: {},
  qrCode: null,
  validDate: null,
  isLoading: false,
  error: null,
};

const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getConfig.pending]: (state) => {
      state.config = {};
      state.registrationId = null;
      state.invitation = {};
      state.qrCode = null;
      state.isLoading = true;
      state.error = null;
    },
    [getConfig.fulfilled]: (state, action) => {
      state.config = action.payload;
      state.registrationId = action.payload.registrationId;
      state.isLoading = false;
      state.error = null;
    },
    [getConfig.rejected]: (state, action) => {
      state.config = {};
      state.registrationId = null;
      state.invitation = {};
      state.qrCode = null;
      state.isLoading = false;
      state.error = action.error.message;
    },
    [register.pending]: (state) => {
      state.qrCode = null;
      state.validDate = null;
      state.periodType = null;
      state.isLoading = true;
      state.error = null;
    },
    [register.fulfilled]: (state, action) => {
      state.qrCode = action.payload.qrCode;
      state.validDate = action.payload.validDate;
      state.periodType = action.payload.periodType;
      state.isLoading = false;
      state.error = null;
    },
    [register.rejected]: (state, action) => {
      state.qrCode = null;
      state.validDate = null;
      state.periodType = null;
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getInvitation.pending]: (state) => {
      state.invitation = {};
      state.isLoading = true;
      state.error = null;
    },
    [getInvitation.fulfilled]: (state, action) => {
      state.invitation = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    [getInvitation.rejected]: (state, action) => {
      state.invitation = {};
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { reducer } = configSlice;

export default reducer;
